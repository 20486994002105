export const SUCCESS_FEEDBACK = 'SUCCESS_FEEDBACK';
export const SUCCESS_REOPEN_REQUEST = 'SUCCESS_REOPEN_REQUEST';
export const ERROR_FEEDBACK = 'ERROR_FEEDBACK';
export const ERROR_REOPEN_REQUEST = 'ERROR_REOPEN_REQUEST';
export const LOADING_FEEDBACK = 'LOADING_FEEDBACK';
export const LOADING_REOPEN_REQUEST = 'LOADING_REOPEN_REQUEST';

export const SUCCESS_ACTIVE = 'SUCCESS_ACTIVE';
export const SUCCESS_FINISHED = 'SUCCESS_FINISHED';
export const ERROR_ACTIVE = 'ERROR_ACTIVE';
export const ERROR_FINISHED = 'ERROR_FINISHED';
export const LOADING_ACTIVE = 'LOADING_ACTIVE';
export const LOADING_FINISHED = 'LOADING_FINISHED';

export const SUCCESS_HELP_REQUEST = 'SUCCESS_HELP_REQUEST';
export const ERROR_HELP_REQUEST = 'ERROR_HELP_REQUEST';
export const LOADING_HELP_REQUEST = 'LOADING_HELP_REQUEST';

export const SUCCESS_GET_ADMINS = 'SUCCESS_GET_ADMINS';
export const ERROR_GET_ADMINS = 'ERROR_GET_ADMINS';
export const LOADING_GET_ADMINS = 'LOADING_GET_ADMINS';

export const SUCCESS_HELP_REQUEST_STATUS = 'SUCCESS_HELP_REQUEST_STATUS';
